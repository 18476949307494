<template>
  <div ref="ctxRef">
    <div class="fx-jaws">
      <svg
        class="fx-top jaws h-auto w-full transform-gpu will-change-transform"
        width="1384"
        height="285"
        viewBox="0 0 1384 285"
        fill="none"
      ></svg>
      <div class="relative z-1 -my-[5vw] pl-4 lg:px-[9vw]">
        <div
          class="slider fx-content flex snap-mandatory gap-2 overflow-hidden overflow-x-scroll lg:gap-4"
        >
          <ProjectListItem
            v-for="(item, idx) in data"
            :key="idx"
            :data="item"
            class="snap-center"
          />
        </div>
      </div>
      <svg
        class="fx-bottom jaws h-auto w-full rotate-180 transform-gpu will-change-transform"
        width="1384"
        height="285"
        viewBox="0 0 1384 285"
        fill="none"
      ></svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import type { ProjectRecord } from '~/types/datocms'

defineProps({
  data: {
    type: Array as PropType<ProjectRecord[]>,
    default: () => []
  }
})

const isMounted = ref(false)
const ctxRef = ref<HTMLElement | null>(null)
let ctx: any = null

onMounted(() => {
  isMounted.value = true

  if (ctxRef.value) {
    ctx = gsap.context((self: any) => {
      const elTop = self.selector('.fx-top')
      const elBottom = self.selector('.fx-bottom')
      const elContent = self.selector('.fx-content')

      gsap.set([self.selector('.fx-jaws')], { autoAlpha: 1 })
      gsap.set([elTop], { y: '60%' })
      gsap.set([elBottom], { y: '-60%' })
      gsap.set([elContent], { autoAlpha: 0 })

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ctxRef.value,
          start: 'top 90%'
        }
      })
      tl.to(self.selector('.fx-jaws'), {
        duration: 0.8,
        autoAlpha: 1,
        ease: 'power1.out'
      })
      tl.to([elTop, elBottom], {
        duration: 0.8,
        y: '0%',
        ease: 'power1.out'
      })
      tl.to(
        elContent,
        {
          duration: 0.8,
          autoAlpha: 1,
          ease: 'power1.out'
        },
        '-=0.5'
      )
    }, ctxRef.value)
  }
})

onUnmounted(() => {
  if (ctx) {
    ctx.revert()
  }
})
</script>

<style lang="postcss" scoped>
.jaws {
  background: url('~/assets/images/borders/jaws.svg') 0 0 / cover no-repeat;
}
</style>
