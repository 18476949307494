<template>
  <div class="min-w-[80vw] lg:min-w-0">
    <NuxtLink :to="localePathByType(data._modelApiKey, data.slug as string) as string" noPrefetch>
      <div class="overflow-hidden">
        <Image
          class="aspect-video transition-transform duration-500 hover:scale-110"
          loading="lazy"
          effect="none"
          :src="data?.image!.responsiveImage ? data?.image!.responsiveImage?.src : data?.image!.url"
          :srcset="
            data?.image!.responsiveImage ? data?.image!.responsiveImage?.webpSrcSet : undefined
          "
          :width="
            data?.image!.responsiveImage ? data?.image!.responsiveImage?.width : data?.image!.width
          "
          :height="
            data?.image!.responsiveImage
              ? data?.image!.responsiveImage?.height
              : data?.image!.height
          "
          sizes="(min-width: 1920px) 296px, (min-width: 1400px) 405px, (min-width: 1060px) calc(26.25vw + 43px), (min-width: 1020px) calc(-665vw + 7231px), (min-width: 780px) calc(37.27vw + 75px), (min-width: 520px) 448px, 90vw"
          :alt="data?.image!.alt!"
          :observerOptions="{ threshold: 0 }"
        />
      </div>
      <div class="mt-4 flex flex-col text-sm text-cyan lg:flex-row lg:justify-between">
        <div class="font-bold">{{ data.title }}</div>
        <div>{{ dateFormatYear(data.yearCompleted) }}</div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import type { ProjectRecord } from '~/types/datocms'

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object as PropType<ProjectRecord>,
    default: () => {}
  }
})

const { dateFormatYear } = useDate()
</script>
